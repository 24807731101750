.left-nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 55px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    display: flex;
    overflow-x: auto;
}

.left-nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    font-family: sans-serif;
    font-size: 13px;
    /*color: #444444;*/
    color: #2b2d42;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;
}

.left-nav__link:hover, .left-nav__link:hover .material-icons {
    background-color: #eeeeee;
    color: #D90429;
    text-decoration: blink;
}

.left-nav__link--active {
    color: #D90429;
    /*    border-top: 2px solid;*/
}

.left-nav__icon {
    font-size: 26px !important;
}

.left__btm_menu, .right__btm_menu {
    width: 50%
}

.left-nav__link.lf {
    width: 45%;
    float: left; 
    margin-top: 5px;
}

.left-nav__link.rt {
    width: 45%;
    float: right;
    margin-top: 5px;
}

.highlight__menu {
    bottom: 27px;
    position: fixed;
    left: 44%;
    padding: 15px 10px 10px 10px;
    border-radius: 50%;
    background: #2B2D42;
}

.highlight__menu i {
    color: #ffffff;
}

a.left-nav__link.lf:hover, .left-nav__link.rt:hover {
    background: none;
}
i.left-nav__icon:focus {
    outline: 0
}

.highlight__menu:hover {
    background: #111;
}

.highlight__menu i:focus {
    outline: 0;
}
.chatBadge {
    position: absolute;
    top: 5px;
    right: 8px;
    border-radius: 50%;
    background-color: #d41a1a;
    color: white;
    width: 20px;
    height: 20px;
    text-align: center;
}