@import url(/assets/fonts/iconic/font-awesome-4.7.0/css/font-awesome.min.css);
@import url(/assets/fonts/iconic/css/material-design-iconic-font.min.css);
@import url(/assets/css/util.css);
@import url(/assets/css/main.css);
@import url(/assets/css/custom-responsive.css);
@import url(/assets/css/style.css);
@import url(/assets/css/custom.css);
@import url(/assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css);
@import url(/assets/fonts/iconic/css/material-design-iconic-font.min.css);
/* @import '/assets/css/tinyDrawer.min.css'; */

/* @import 'material-icons/css/material-icons.scss'; */

.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
  }
  
  .toggle-button:focus {
    outline: none;
  }
  
  .toggle-button__line {
    width: 25px;
    height: 2px;
    background: white;
  }


.toolbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 56px;
    background: #D90429;
}

.toolbar__navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem
}

.toolbar__logo a {
     color: white;
     text-decoration: none;
     font-size: 1.5rem;
}

.spacer {
    flex: 1 1;
}

.toolbar__logo {
    /* margin-left: 1rem; */
    margin-left: 30%;
}

.toolbar_navigation-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar_navigation-items li {
    padding: 0 1.5rem;
}

.toolbar_navigation-items a {
    color: white;
    text-decoration: none;
}

.toolbar_navigation-items a:hover, .toolbar_navigation-items a:active {
    color: orange;
    text-decoration: none;
}

@media (max-width: 768px) {
    .toolbar_navigation-items {
        display: none;
    }
}

/* @media (min-width: 769px) {
    .toggle___button {
        display: none;
    }
} */


.searchIcons {
    padding: 0
}
.bellIcons {
    padding: 0; margin-left: 20px;
}
.ellipseIcons {
    float: right;margin-right: 10px; margin-left: 20px;
}
.top_toolbr {
    display: inline-flex;
}

.ticket_cancel {
    font-size: 22px;
    color: #fff;
    margin-left: 8px;
    margin-top: 4px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 45px;
    right: 0;
}
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
.show {
    display: block;
}

.badge {
    position: absolute;
    top: 6px;
    right: 3%;
    border-radius: 50%;
    background-color: #2B2D42;
    color: white;
    width: 20px;
    height: 20px;
    padding-top: 5px;
  }

  @media (max-width: 1220px) {
    .badge {
        right: 4%
    }
  }
  @media (max-width: 1010px) {
    .badge {
        right: 5%
    }
  }
  @media (max-width: 756px) {
    .badge {
        right: 6%
    }
  }
  @media (max-width: 670px) {
    .badge {
        right: 7%
    }
  }
  @media (max-width: 576px) {
    .badge {
        right: 8%
    }
  }
  @media (max-width: 480px) {
    .badge {
        right: 9%
    }
  }
  @media (max-width: 420px) {
    .badge {
        right: 10%
    }
  }
  @media (max-width: 370px) {
    .badge {
        right: 13%
    }
  }
.side-drawer {
  height: 100%;
  background: white;
  /* box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5); */
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
  overflow: auto;
}

@media (min-width: 769px) {
  /* .side-drawer {
    display: none;
  } */
}

/* CSS Starts Here */


li {
  float: none;
  line-height: 79px;
}

.user-view {
  position: relative;
  padding: 32px 32px 0;
  margin-bottom: 8px;
}

.user-view .circle {
  height: 64px;
  width: 64px;
}

.user-view .circle,
.user-view .name,
.user-view .email {
  display: block;
}

.circle {
  border-radius: 50%;
}

.user-view .name {
  margin-top: 1px;
  font-weight: 500;
}

.user-view .name,
.user-view .email {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.6px;
  font-family: 'poppins', sans-serif;
}

.user-view .background {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.user-view .background img {
  height: 100%;
}

.user-view>a {
  height: auto;
  padding: 0;
  color: #fff;
  text-decoration: none;
}

li>a {
  color: #8D99AE;
  display: block;
  font-size: 14px;
  font-weight: 600;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
  text-decoration: none;
}

li>a>i,
li>a>[class^="mdi-"],
li>a li>a>[class*="mdi-"],
li>a>i.material-icons {
  float: left;
  height: 48px;
  line-height: 48px;
  margin: 0 32px 0 0;
  width: 24px;
  color: #8D99AE;
}

li.nv__menu {
  border-bottom: 1px solid rgba(170, 170, 170, 0.1);
}

.left-nav__link:focus {
  outline: none;
  text-decoration: none;
  background: none;
}

.nv_submenu {
  border-bottom: none !important;
}

.pull-right-count {
  float: right;
}

.active__menu_cl {
  color: #D90429 !important;
}

.active__menu {
  background: rgba(216, 43, 42, 0.2);
  color: #D90429;
}

li.nv__menu a:hover {
  color: #8D99AE;
  text-decoration: none;
}
.white-text {
  color: #ffffff;
}
.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 100;  
}
.left-nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 55px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    display: flex;
    overflow-x: auto;
}

.left-nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    font-family: sans-serif;
    font-size: 13px;
    /*color: #444444;*/
    color: #2b2d42;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;
}

.left-nav__link:hover, .left-nav__link:hover .material-icons {
    background-color: #eeeeee;
    color: #D90429;
    text-decoration: blink;
}

.left-nav__link--active {
    color: #D90429;
    /*    border-top: 2px solid;*/
}

.left-nav__icon {
    font-size: 26px !important;
}

.left__btm_menu, .right__btm_menu {
    width: 50%
}

.left-nav__link.lf {
    width: 45%;
    float: left; 
    margin-top: 5px;
}

.left-nav__link.rt {
    width: 45%;
    float: right;
    margin-top: 5px;
}

.highlight__menu {
    bottom: 27px;
    position: fixed;
    left: 44%;
    padding: 15px 10px 10px 10px;
    border-radius: 50%;
    background: #2B2D42;
}

.highlight__menu i {
    color: #ffffff;
}

a.left-nav__link.lf:hover, .left-nav__link.rt:hover {
    background: none;
}
i.left-nav__icon:focus {
    outline: 0
}

.highlight__menu:hover {
    background: #111;
}

.highlight__menu i:focus {
    outline: 0;
}
.chatBadge {
    position: absolute;
    top: 5px;
    right: 8px;
    border-radius: 50%;
    background-color: #d41a1a;
    color: white;
    width: 20px;
    height: 20px;
    text-align: center;
}
.main-assin_num {
    /* text-align: center; */
    /* position: absolute; */
    position: relative;
    /* left: 28%; */
}

.fix__crc {
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
}

.main__top_dsh {
    background: #fff;
    text-align: center;
}

.main__top_dsh_main {
    padding: 15% 0;
}

.main__top_dsh_main .asign__count {
    text-align: center;
}


ul.main-assin_num {
    list-style: none;
}

ul.main-assin_num li {
    display: inline-block;
    background-color: #ffffff;
    margin: 15px 0 0 0;
    border-radius: 50%;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

ul.main-assin_num li a {
    color: #2B2D42;
    display: table-cell;
    /*	 vertical-align: middle;*/
    text-align: center;
    text-decoration: none;
    height: 150px;
    width: 150px;
    font-size: 38px;
    padding-top: 22px;
}

ul.main-assin_num li a:hover,
ul.main-assin_num li a:active {
    color: #2B2D42 !important;
    text-decoration: none;
}

.ticket__section {
    border: 1px solid rgba(170, 170, 170, 0.5);
    border-top: 0;
    border-right: none;
    border-left: none;
}

.fixes__head {
    padding: 8px 10px;
    font-size: 16px;
    font-weight: bold;
    color: #D90429;
}

.fixes__head a {
    padding: 8px 10px;
    font-size: 16px;
    font-weight: bold;
    color: #D90429;
}

.hide__container {
    display: none;
}

.pad-4{
    padding-bottom: 4%;
}
.pad-2{
    padding-bottom: 2%;
}
.pad-1{
    padding-bottom: 1%;
}
.color-green
{
    color: #2b2d42;
}
.color-dark{
    color: #2b2d42;
}
.btn-success{
    color: #fff;
    background-color: #2b2d42;
    border-color: #2b2d42;
    border-radius: 0px;
}
.btn-danger{
    color: #fff;
    background-color: #8D99AE;
    border-color: #8D99AE;
    border-radius: 0px;
}
.btn-custom{
    color: #fff;
    background-color: #D90429;
    border-color: #D90429;
    border-radius: 0px;
}
.custom-amount-btn {
    font-size: 14px;
    padding: 7px 25px;
    border-radius: 20px;
}
.m-b-2{
    margin-bottom: 2%;
}

.empty-ticket-img{
  height: 200px;
  width: auto;
  padding-bottom: 1%;
}
.pad-t-5 {
    padding-top: 5%;
}

.you-are-in-chat {
    /* box-shadow: 5px 10px 18px #888888;
    position: absolute;
    right: 0;
    padding: 10px; */

    /* box-shadow: 5px 5px 10px #888888f7;
    position: absolute;
    right: 0;
    padding: 5px;
    z-index: 1;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 12px;
    font-weight: 500; */

    padding: 2px 5px;
    font-size: 12px;
    font-weight: 600;
    background: #2B2D42;
}
 .chatContainer .you-are-in-chat {
    color: #8D99AE;
}
.chatContainer {
    text-decoration: none;
}

.cs_ficn_cls {
    font-size: 12px;
    margin-right: 5px;
    color: #2B2D42;
    width: 16px;
}

i.fa.fa-user.cs_ficn_cls {
    margin-right: 11px;
}

.chat-online-icn {
    color: #D90429;
    /* margin-top: 7px; */
    padding: 10px;
    /* margin-right: 5px */
}

.chat-online-blink {
    -webkit-animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;
            animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
  }
  @-webkit-keyframes blinker {  
    from { opacity: 1; }
    to { opacity: 0; }
  }
  @keyframes blinker {  
    from { opacity: 1; }
    to { opacity: 0; }
  }
.prof_hd.header__title__main {
    color: #ffffff;
    margin-left: 5%;
    font-size: 16px;
}

.profEditBtnPnc {
    color: #ffffff;
}

.backBtnClas {
    font-size: 22px;
    color: #fff;
    margin-left: 10px;
    margin-top: 4px;
}
.colorWhite {
    color: #ffffff;
}
.fixes__head {
    padding: 8px 10px;
    font-size: 16px;
    font-weight: bold;
    color: #D90429;
}

.fixes__content_sng {
    padding: 10px;
    margin: 0px 10px;
    background: white;
    border-radius: 5px;
    margin-bottom: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.spacee {
    flex: 1 1;
}

.fixes__content_sng_hd {
    display: flex;
}

.fixes__content_sng_hd .fixes__content_sng_hd_ttl {
    font-size: 16px;
    font-weight: bold;
}

.fixes__content_sng .trk__id_nmbr {
    font-size: 13px;
    margin-top: 6px;
}

.fixes__content_ftr {
    display: flex;
}

p.fixes__content_sng_hd_dte {
    font-size: 14px;
}

.fixes__content_ftr a {
    font-size: 12px;
    border-radius: 20px;
    border: 1px solid;
    padding: 3px 10px;
    margin-top: 12px;
    color: #8D99AE;
    text-decoration: none;
}

.fixes__content_ftr .sts {
    margin-top: 12px;
    font-size: 13px;
    font-weight: 600;
}

.ldetails.list_cases a {
    color: #8D99AE;
}

.text-success {
    color: #D90429;
}
.text-component.list-tkt {
    border-bottom: 2px solid #eee; 
    background: #fff;
}
.ticket__desc_status .ticket_status {
    font-size: 14px;margin-bottom: 0px;color: #2b2d42;
}
.ticket__desc_decs .desc_attch {
    font-size: 22px;color: #111;margin-top: 4px;
}

.ticket__desc_decs .desc_attch_ic {
    float: right
}
.ticket__desc_decs .desc_attch_ic > i {
    font-size: 22px;color: #111;margin-top: 4px;margin-right: 10px;
}
.ticket__desc_decs .desc_histr {
    font-size: 22px;color: #111;margin-top: 4px; margin-right: 20px;
}
.ticket__desc_decs .ttl__des {
    font-size: 15px;line-height: 24px;
}


#mybutton {
    position: fixed;
    bottom: 85px;
    width: 100%;
}
.feedback {
    background-color: #D90429;
    color: white;
    padding: 10px 20px;
    border-radius: 0px;
    border-color: #D90429;
    width: 100%;
    border: none;
    width: 50%;
    float: left;
}

.feedback.accept {
    background: #2b2d42 !important;
}
/* h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    line-height: .9;
    font-weight: 700;
    color: #2B2D42;
} */

.profile-block {
    position: relative;
    overflow: hidden;
    max-width: 100%;
}

.profile-block .user-heading {
    background: #ffffff none repeat scroll 0 0;
    border-radius: 4px 4px 0 0;
    color: #fff;
    padding: 20px;
    text-align: center;
}

.profile-block .user-heading a img {
    border-radius: 50%;
    margin: auto auto 10px;
    display: block;
    height: 140px;
    width: 140px;
    border: 3px solid #D90429;
}

.profile-block .user-heading h1 {
    color: #111;
    font-size: 20px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 10px;
    margin-top: 5px;
    font-weight: 600;
}

.profile-block .user-heading p {
    color: #111;
    font-size: 12px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
}

.profile-block ul {
    padding: 0;
    margin: 0;
    display: block;
    background: #ffffff;
}

.profile-block ul > li {
    line-height: 30px;
    margin-top: 0;
}

.profile-block .nav > li > a {
/*    background: #e7e7e7;*/
    border-radius: 0;
    color: #000;
    display: block;
    font-size: 14px;
    padding: 5px 20px;
    position: relative;
    text-align: left;
    text-decoration: none;
}

.profile-block ul > li > a > i {
    color: #D90429;
    font-size: 16px;
    padding-right: 10px;
}

.profile-block ul > li > a:hover,
.profile-block ul > li > a:focus,
.profile-block ul li.active a {
    background: #fff !important;
    border-radius: 0;
    color: #000 !important;
}

.nextt {
    float: right;
    margin-top: 5px;
}

li>a>i, li>a>[class^="mdi-"], li>a li>a>[class*="mdi-"], li>a>i.material-icons {
    float: none;
    margin: 0;
}

.ticket__desc_decs {
    font-family: 'poppins', sans-serif;
}
.ticket__desc_decs .descp {
    font-size: 14px;line-height: 24px; font-weight: 500;
}
.head_label {
    font-weight: bold
}
/* .nav.nav-pills.nav-stacked {
    margin-top: 15px;
} */
.stacked_lst_itm {
    border: 1px solid rgb(237 242 244 / 0.2);
}

/* .main-profilee {
    padding-top: 15px
} */

.profile-block {
    position: relative;
    overflow: hidden;
    max-width: 100%;
}

.profile-block .user-heading {
    background: #D90429 none repeat scroll 0 0;
    border-radius: 4px 4px 0 0;
    color: #fff;
    padding: 20px;
    text-align: center;
}

.profile-block .user-heading a img {
    border-radius: 50%;
    margin: auto auto 10px;
    display: block;
    height: 140px;
    width: 140px;
    border: 10px solid rgba(255, 255, 255, 0.3);
}

.profile-block .user-heading h1 {
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.profile-block .user-heading p {
    color: #fff;
    font-size: 12px;
    margin-bottom: 10px;
    text-align: center;
}

.profile-block ul {
    padding: 0;
    margin: 0;
    display: block
}

.profile-block ul > li {
    line-height: 30px;
    margin-top: 0;
}

.profile-block .nav > li > a {
/*    background: #e7e7e7;*/
    border-radius: 0;
    color: #000;
    display: block;
    font-size: 14px;
    padding: 10px 20px;
    position: relative;
    text-align: left;
    text-decoration: none;
    font-weight: 500
}

.profile-block ul > li > a > i {
    color: #000;
    font-size: 16px;
    padding-right: 10px;
}

.profile-block ul > li > a:hover,
.profile-block ul > li > a:focus,
.profile-block ul li.active a {
    background: #fff !important;
    border-radius: 0;
    color: #000 !important;
    font-weight: 500 !important;
}

.nextt {
    float: right;
    margin-top: 5px;
}

.right__menu_cr.text-center img {
    height: 150px;
}
.cert-inp {
    border: 0 !important; padding: 0
}
.main-top-top {
    padding-top: 15px
}
.btn-cntue_pr {
    width: 100%;text-align: center;padding: 5px 10px;background: #D90429; border-radius: 20px;
}
@media (min-width: 600px) {
    .main-top-top {
        width: 35%;
    }
}
a.contue-btn {
    text-decoration: none;
    color: #fff;
}
label {
    font-size: 14px;
}
.toolbar {
    z-index: 1;
}
.skills-drp {
    margin: 0 !important;
    padding: 0 10px !important;
}
/* select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
} */



  /* checkbox */

  input[type=checkbox] + label {
    display: block;
    margin: 0.2em;
    cursor: pointer;
    padding: 0.2em;
  }
  
  input[type=checkbox] {
    display: none;
  }
  
  input[type=checkbox] + label:before {
    content: "\2714";
    border: 0.1em solid #000;
    border-radius: 0.2em;
    display: inline-block;
    width: 25px;
    height: 25px;
    padding-left: 0.2em;
    padding-bottom: 0.3em;
    margin-right: 10px;
    vertical-align: bottom;
    color: transparent;
    transition: .2s;
  }
  
  input[type=checkbox] + label:active:before {
    transform: scale(0);
  }
  
  input[type=checkbox]:checked + label:before {
    background-color: #D90429;
    border-color: #D90429;
    color: #fff;
  }
  
  input[type=checkbox]:disabled + label:before {
    transform: scale(1);
    border-color: #aaa;
  }
  
  input[type=checkbox]:checked:disabled + label:before {
    transform: scale(1);
    background-color: #bfb;
    border-color: #bfb;
  }

  /* checkboc end */

.txt-fld {
    font-size: 14px;
}

.select__skill_category {
  width: 100%;
}

.basic-multi-select.css-2b097c-container {
  width: 100%;
}

.select__control.css-yk16xz-control {
  border-radius: 20px;
  padding: 3px;
}
.ad__tk__rgt .form-control {
    border: none !important;
    margin: 0;
    padding: 0;
}
.row__menu_cr {
    padding: 5px;
}
.left__menu_cr, .right__menu_cr {
    width: 50%;
    float: left;
    padding: 5px;
}
.skillList__pr {
    padding: 5px;
}

.skillList__pr i {
    padding-right: 10px;
    color: #D90429
}
.tickmarkClass {
    position: fixed;
    z-index: 10;
    /* right: 16px; */
    right: 27%;
    top: 14px;
}
.tickmarkClass a i {
    color: #ffffff;
}
.fixesAcceptConfirmation {
    display: flex;
    /* margin-top: 5px */
}

 .fixesAcceptConfirmation p {
    width: 50%;
    text-align: center;
    line-height: 1.4;
}

.fixesAcceptConfirmation .reject {
    background: #D90429;
    color: #ffffff;
    border-radius: 20px;
    padding: 5px;
    margin: 0 5px;
}

.fixesAcceptConfirmation .accept {
    background: #2B2D42;
    color: #ffffff;
    border-radius: 20px;
    padding: 5px;
    margin: 0 5px;
}

.fixesAcceptConfirmation .snooze {
    background: #8D99AE;
    color: #ffffff;
    border-radius: 20px;
    padding: 5px;
    margin: 0 5px;
}
.fixesAcceptConfirmation .accept .material-icons,
.fixesAcceptConfirmation .reject .material-icons, 
.fixesAcceptConfirmation .snooze .material-icons {
    color: #ffffff;
    font-size: 14px;
    margin-right: 5px;
}

.swal2-popup {
    width: 300px;
}
.swal2-title {
    font-size: 16px;
}
.swal2-content {
    font-size: 14px;
}
.swal2-styled {
    padding: 5px 12px;
}
.swal2-styled:focus {
    box-shadow: none;
}
.connectmsg {
    padding: 5px 10px;
}
.snze-msg {
    padding: 5px 10px;
}
.container-chat {
    margin: 0 auto;
    width: 100%;
    background: #444753;
}

.people-list {
    width: 260px;
    float: left;
}

.people-list .search {
    padding: 20px;
}

.people-list input {
    border-radius: 3px;
    border: none;
    padding: 14px;
    color: white;
    background: #6A6C75;
    width: 90%;
    font-size: 14px;
}

.people-list .fa-search {
    position: relative;
    left: -25px;
}

.people-list ul {
    padding: 20px;
    height: 770px;
}

.people-list ul li {
    padding-bottom: 20px;
}

.people-list img {
    float: left;
}

.people-list .about {
    float: left;
    margin-top: 8px;
}

.people-list .about {
    padding-left: 8px;
}

.people-list .status {
    color: #92959E;
}

li.clearfix {
    line-height: normal;
}
.chat {
    width: 100%;
    float: left;
    background: #F2F5F8;
    color: #434651;
}

.chat .chat-header {
    padding: 10px 20px;
    border-bottom: 2px solid white;
}

.chat .chat-header img {
    float: left;
}

.chat .chat-header .chat-about {
    float: left;
    padding-left: 10px;
    margin-top: 6px;
}

.chat .chat-header .chat-with {
    font-weight: bold;
    font-size: 16px;
}

.chat .chat-header .chat-num-messages {
    color: #92959E;
}

.chat .chat-header .fa-star {
    float: right;
    color: #D8DADF;
    font-size: 20px;
    margin-top: 12px;
}

.chat .chat-history {
    padding: 0px 30px 20px;
    border-bottom: 2px solid white;
    /* overflow-y: scroll; */
    overflow-y: auto;
    height: 85vh;
    height: 77vh;
}

.chat .chat-history .message-data {
    margin-bottom: 15px;
}

.chat .chat-history .message-data-time {
    color: #a8aab1;
    padding-left: 6px;
    font-size: 14px;
}

.chat .chat-history .message {
    color: white;
    padding: 8px 20px;
    line-height: 25px;
    font-size: 14px;
    border-radius: 7px;
    margin-bottom: 10px;
    width: 90%;
    position: relative;
}

.chat .chat-history .message:after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #EDF2F4;
    border-width: 10px;
    margin-left: -10px;
}

.chat .chat-history .my-message {
    background: #8D99AE;
    color: #ffffff;
}

.chat .chat-history .other-message {
    background: #D90429;
}

.chat .chat-history .other-message:after {
    border-bottom-color: #D90429;
    left: 93%;
}

.chat .chat-message {
    padding: 0;
    bottom: 0;
    position: fixed;
    background: #EDF2F4;
    /* background: #eeeeee; */
    width: 100%;
}

.chat .chat-message textarea {
    width: 100%;
    border: none;
    height: 60px;
    padding: 10px;
    font: 14px/22px "Lato", Arial, sans-serif;
    margin-bottom: 10px;
    resize: none;
}

.chat .chat-message .fa-file-o,
.chat .chat-message .fa-file-image-o {
    font-size: 20px;
    color: gray;
    cursor: pointer;
}

.chat .chat-message button {
    float: right;
    color: #337ab7;
    font-size: 16px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
}

.chat .chat-message button:hover {
    color: #286090;
}

.online,
.offline,
.me {
    margin-right: 3px;
    font-size: 10px;
}

.online {
    color: #86BB71;
}

.offline {
    color: #E38968;
}

.me {
    color: #D90429;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.float-right {
    float: right;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.no-pad {
    padding-left: 0px;
    padding-right: 0px;
}

header.cd-main-header.js-cd-main-header {
    width: 100%;
}

.cd-main-header {
    position: fixed;
}

.custom-top-h5 {
    width: 100%;
    /*  text-align: center;*/
    /*  position: absolute;*/
    font-size: 14px;
}

span.message-data-name {
    font-size: 14px;
}

.chat_mat_icn {
    font-size: 14px;
    color: #ffffff;
    padding-left: 5px;
}
.send-cht-btn {
    /* background: #D90429;
    padding: 0 8px;
    border-radius: 3px;
    color: #ffffff !important; */

    background: #D90429;
    /* padding: 0 8px; */
    padding: 3px 15px;
    border-radius: 20px;
    color: #ffffff !important;
    margin: 5px;
}

.chat .chat-history .my-message.cht-immg {
    background: transparent;
}

.chat .chat-history .other-message.cht-immg:after {
    border-bottom-color: transparent !important;
}

.chat .chat-history .other-message.cht-immg {
    background: transparent;
}

.message.other-message.float-right.cht-immg > [data-rmiz-wrap="visible"], [data-rmiz-wrap="hidden"] {
    float: right;
}

/* ---------------------Chat loading spinner--------------------- */

.typing-loader {
    display: none;
    width: 6px;
    height: 6px;
    -webkit-animation: line 1s linear infinite alternate;
   animation: line 1s linear infinite alternate;

   position: absolute;
    bottom: 15%;
    left: 45%;
}
.typing-loader.show {
    display: block;
}
@-webkit-keyframes line{
    0%{
       
        background-color: #D90429;
        box-shadow: 12px 0px 0px 0px #D90429, 
                    24px 0px 0px 0px #D90429;
                    
      }
    25%{ 
            background-color: #D90429;
            box-shadow: 12px 0px 0px 0px #D90429, 
                    24px 0px 0px 0px #D90429;
                    
    }
    75%{ background-color: #D90429;
        box-shadow: 12px 0px 0px 0px #D90429, 
                    24px 0px 0px 0px #D90429;
                   
      }
}

@keyframes line{
    0%{
       
        background-color: #D90429;
        box-shadow: 12px 0px 0px 0px #D90429, 
                    24px 0px 0px 0px #D90429;
                    
      }
    25%{ 
            background-color: rgba(0,0,0, 0.4);
            box-shadow: 12px 0px 0px 0px #D90429, 
                    24px 0px 0px 0px #D90429;
                    
    }
    75%{ background-color: rgba(0,0,0, 0.4);
        box-shadow: 12px 0px 0px 0px #D90429, 
                    24px 0px 0px 0px #D90429;
                   
      }
}
.demo-image-preview {
    position: relative;
    text-align: center;
  }
  
  .demo-image-preview > img {
    width: 768px;
  }
  
  @media(max-width:768px){
    .demo-image-preview > img {
      width: 100%;
    }
  }
  
  /* fullscreen enable by props */
  .demo-image-preview-fullscreen > img {
    width: 100vw;
    height:100vh;
  }
.custom-top-search{
    width: 100%;
  /*  text-align: center;*/
  /*  position: absolute;*/
    color: #ffffff;
  }
  .custom-chat-title{
      padding: 25px 0 10px 0;
  }
  .custom-h4{
      color: #2b2d42;
      font-weight: bold;
      font-size: 18px;
  }
  .custom-chat-p{
      font-size: 13px;
      margin-top: 2%;
      color: #a4a4a4;
  }
  .custom-chat-grid{
      padding: 10px 20px;
  }
  .chat-p-img{
      width: 100%;
      height: auto;
  }
  .custom-chat-name{
      margin-top: 5%;
      font-size: 14px;
      font-weight: bold;
      color: #2b2d42;
  }
  .custom-last-chat{
      margin-top: 1%;
      font-size: 13px;
      color: #a4a4a4;
  }
  .no-pl{
      padding-left: 0px;
  }
  .chat-profile-spacing{
      padding: 5px 0;
  }
  .chat-active{
      background: #f3f4f9;
      border-left: 5px solid #D90429;
  }
  .custom-scroll{
  /*	height: 85vh;*/
      overflow-y: scroll;
  }
  header.cd-main-header.js-cd-main-header {
      position: fixed;
      width: 100%;
  }
.font-18 {
    font-size: 18px;
}

.font-bold {
    font-weight: bold;
}

.mt-3 {
    margin-top: 3%;
}

.custom-legal-p-tag {
    margin: 2% 0;
    text-align: justify;
}

.custom-legal-sub-title {
    font-weight: bold;
    font-size: 14px;
    margin-top: 2%;
}

.custom-legal-sub-ul-title {
    font-size: 14px;
    margin-top: 2%;
}

.pad-l-5 {
    padding-left: 5%;
}

.pad-l-10 {
    padding-left: 10%;
}

.pad-l-12 {
    padding-left: 12%;
}

.user_pending_amnt {
    margin: 10px;
    background: white;
    padding: 10% 0;
}
.user__pmnt_sng_cr {
    padding: 10px;
    margin: 10px;
    background: #ffffff;
    border-radius: 5px;
}
.color_ts {
    color: #D90429;
}
.fnt_bld {
    font-weight: bold
}
.text_pend_ttl {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 16px;
    font-weight: 600;
}
.text_pend_cnt {
    padding-top: 5px;
    font-size: 20px;
    color: #D90429;
    font-weight: bold;
    letter-spacing: 2px;
}
.faq-head {
    color: #d90429;
    font-weight: bold;
}
.faq-single {
    margin: 10px 0;
}

.faq-head-ans {
    color: #8D99AE;
    padding: 10px 0;
}

.faq-head-ans-list {
    color: #8D99AE;
    padding: 5px 0;
}
