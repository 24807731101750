/* h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    line-height: .9;
    font-weight: 700;
    color: #2B2D42;
} */

.profile-block {
    position: relative;
    overflow: hidden;
    max-width: 100%;
}

.profile-block .user-heading {
    background: #ffffff none repeat scroll 0 0;
    border-radius: 4px 4px 0 0;
    color: #fff;
    padding: 20px;
    text-align: center;
}

.profile-block .user-heading a img {
    border-radius: 50%;
    margin: auto auto 10px;
    display: block;
    height: 140px;
    width: 140px;
    border: 3px solid #D90429;
}

.profile-block .user-heading h1 {
    color: #111;
    font-size: 20px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 10px;
    margin-top: 5px;
    font-weight: 600;
}

.profile-block .user-heading p {
    color: #111;
    font-size: 12px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
}

.profile-block ul {
    padding: 0;
    margin: 0;
    display: block;
    background: #ffffff;
}

.profile-block ul > li {
    line-height: 30px;
    margin-top: 0;
}

.profile-block .nav > li > a {
/*    background: #e7e7e7;*/
    border-radius: 0;
    color: #000;
    display: block;
    font-size: 14px;
    padding: 5px 20px;
    position: relative;
    text-align: left;
    text-decoration: none;
}

.profile-block ul > li > a > i {
    color: #D90429;
    font-size: 16px;
    padding-right: 10px;
}

.profile-block ul > li > a:hover,
.profile-block ul > li > a:focus,
.profile-block ul li.active a {
    background: #fff !important;
    border-radius: 0;
    color: #000 !important;
}

.nextt {
    float: right;
    margin-top: 5px;
}

li>a>i, li>a>[class^="mdi-"], li>a li>a>[class*="mdi-"], li>a>i.material-icons {
    float: none;
    margin: 0;
}

.ticket__desc_decs {
    font-family: 'poppins', sans-serif;
}
.ticket__desc_decs .descp {
    font-size: 14px;line-height: 24px; font-weight: 500;
}
.head_label {
    font-weight: bold
}
/* .nav.nav-pills.nav-stacked {
    margin-top: 15px;
} */
.stacked_lst_itm {
    border: 1px solid rgb(237 242 244 / 0.2);
}
