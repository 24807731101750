.container-chat {
    margin: 0 auto;
    width: 100%;
    background: #444753;
}

.people-list {
    width: 260px;
    float: left;
}

.people-list .search {
    padding: 20px;
}

.people-list input {
    border-radius: 3px;
    border: none;
    padding: 14px;
    color: white;
    background: #6A6C75;
    width: 90%;
    font-size: 14px;
}

.people-list .fa-search {
    position: relative;
    left: -25px;
}

.people-list ul {
    padding: 20px;
    height: 770px;
}

.people-list ul li {
    padding-bottom: 20px;
}

.people-list img {
    float: left;
}

.people-list .about {
    float: left;
    margin-top: 8px;
}

.people-list .about {
    padding-left: 8px;
}

.people-list .status {
    color: #92959E;
}

li.clearfix {
    line-height: normal;
}
.chat {
    width: 100%;
    float: left;
    background: #F2F5F8;
    color: #434651;
}

.chat .chat-header {
    padding: 10px 20px;
    border-bottom: 2px solid white;
}

.chat .chat-header img {
    float: left;
}

.chat .chat-header .chat-about {
    float: left;
    padding-left: 10px;
    margin-top: 6px;
}

.chat .chat-header .chat-with {
    font-weight: bold;
    font-size: 16px;
}

.chat .chat-header .chat-num-messages {
    color: #92959E;
}

.chat .chat-header .fa-star {
    float: right;
    color: #D8DADF;
    font-size: 20px;
    margin-top: 12px;
}

.chat .chat-history {
    padding: 0px 30px 20px;
    border-bottom: 2px solid white;
    /* overflow-y: scroll; */
    overflow-y: auto;
    height: 85vh;
    height: 77vh;
}

.chat .chat-history .message-data {
    margin-bottom: 15px;
}

.chat .chat-history .message-data-time {
    color: #a8aab1;
    padding-left: 6px;
    font-size: 14px;
}

.chat .chat-history .message {
    color: white;
    padding: 8px 20px;
    line-height: 25px;
    font-size: 14px;
    border-radius: 7px;
    margin-bottom: 10px;
    width: 90%;
    position: relative;
}

.chat .chat-history .message:after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #EDF2F4;
    border-width: 10px;
    margin-left: -10px;
}

.chat .chat-history .my-message {
    background: #8D99AE;
    color: #ffffff;
}

.chat .chat-history .other-message {
    background: #D90429;
}

.chat .chat-history .other-message:after {
    border-bottom-color: #D90429;
    left: 93%;
}

.chat .chat-message {
    padding: 0;
    bottom: 0;
    position: fixed;
    background: #EDF2F4;
    /* background: #eeeeee; */
    width: 100%;
}

.chat .chat-message textarea {
    width: 100%;
    border: none;
    height: 60px;
    padding: 10px;
    font: 14px/22px "Lato", Arial, sans-serif;
    margin-bottom: 10px;
    resize: none;
}

.chat .chat-message .fa-file-o,
.chat .chat-message .fa-file-image-o {
    font-size: 20px;
    color: gray;
    cursor: pointer;
}

.chat .chat-message button {
    float: right;
    color: #337ab7;
    font-size: 16px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
}

.chat .chat-message button:hover {
    color: #286090;
}

.online,
.offline,
.me {
    margin-right: 3px;
    font-size: 10px;
}

.online {
    color: #86BB71;
}

.offline {
    color: #E38968;
}

.me {
    color: #D90429;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.float-right {
    float: right;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.no-pad {
    padding-left: 0px;
    padding-right: 0px;
}

header.cd-main-header.js-cd-main-header {
    width: 100%;
}

.cd-main-header {
    position: fixed;
}

.custom-top-h5 {
    width: 100%;
    /*  text-align: center;*/
    /*  position: absolute;*/
    font-size: 14px;
}

span.message-data-name {
    font-size: 14px;
}

.chat_mat_icn {
    font-size: 14px;
    color: #ffffff;
    padding-left: 5px;
}
.send-cht-btn {
    /* background: #D90429;
    padding: 0 8px;
    border-radius: 3px;
    color: #ffffff !important; */

    background: #D90429;
    /* padding: 0 8px; */
    padding: 3px 15px;
    border-radius: 20px;
    color: #ffffff !important;
    margin: 5px;
}

.chat .chat-history .my-message.cht-immg {
    background: transparent;
}

.chat .chat-history .other-message.cht-immg:after {
    border-bottom-color: transparent !important;
}

.chat .chat-history .other-message.cht-immg {
    background: transparent;
}

.message.other-message.float-right.cht-immg > [data-rmiz-wrap="visible"], [data-rmiz-wrap="hidden"] {
    float: right;
}

/* ---------------------Chat loading spinner--------------------- */

.typing-loader {
    display: none;
    width: 6px;
    height: 6px;
    -webkit-animation: line 1s linear infinite alternate;
   -moz-animation: line 1s linear infinite alternate;
   animation: line 1s linear infinite alternate;

   position: absolute;
    bottom: 15%;
    left: 45%;
}
.typing-loader.show {
    display: block;
}
@-webkit-keyframes line{
    0%{
       
        background-color: #D90429;
        box-shadow: 12px 0px 0px 0px #D90429, 
                    24px 0px 0px 0px #D90429;
                    
      }
    25%{ 
            background-color: #D90429;
            box-shadow: 12px 0px 0px 0px #D90429, 
                    24px 0px 0px 0px #D90429;
                    
    }
    75%{ background-color: #D90429;
        box-shadow: 12px 0px 0px 0px #D90429, 
                    24px 0px 0px 0px #D90429;
                   
      }
}

@-moz-keyframes line{
    0%{
       
        background-color: #D90429;
        box-shadow: 12px 0px 0px 0px #D90429, 
                    24px 0px 0px 0px #D90429;
                    
      }
    25%{ 
            background-color: rgba(0,0,0, 0.4);
            box-shadow: 12px 0px 0px 0px #D90429, 
                    24px 0px 0px 0px #D90429;
                    
    }
    75%{ background-color: rgba(0,0,0, 0.4);
        box-shadow: 12px 0px 0px 0px #D90429, 
                    24px 0px 0px 0px #D90429;
                   
      }
}

@keyframes line{
    0%{
       
        background-color: #D90429;
        box-shadow: 12px 0px 0px 0px #D90429, 
                    24px 0px 0px 0px #D90429;
                    
      }
    25%{ 
            background-color: rgba(0,0,0, 0.4);
            box-shadow: 12px 0px 0px 0px #D90429, 
                    24px 0px 0px 0px #D90429;
                    
    }
    75%{ background-color: rgba(0,0,0, 0.4);
        box-shadow: 12px 0px 0px 0px #D90429, 
                    24px 0px 0px 0px #D90429;
                   
      }
}