.faq-head {
    color: #d90429;
    font-weight: bold;
}
.faq-single {
    margin: 10px 0;
}

.faq-head-ans {
    color: #8D99AE;
    padding: 10px 0;
}

.faq-head-ans-list {
    color: #8D99AE;
    padding: 5px 0;
}