.cert-inp {
    border: 0 !important; padding: 0
}
.main-top-top {
    padding-top: 15px
}
.btn-cntue_pr {
    width: 100%;text-align: center;padding: 5px 10px;background: #D90429; border-radius: 20px;
}
@media (min-width: 600px) {
    .main-top-top {
        width: 35%;
    }
}
a.contue-btn {
    text-decoration: none;
    color: #fff;
}
label {
    font-size: 14px;
}
.toolbar {
    z-index: 1;
}
.skills-drp {
    margin: 0 !important;
    padding: 0 10px !important;
}
/* select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
} */



  /* checkbox */

  input[type=checkbox] + label {
    display: block;
    margin: 0.2em;
    cursor: pointer;
    padding: 0.2em;
  }
  
  input[type=checkbox] {
    display: none;
  }
  
  input[type=checkbox] + label:before {
    content: "\2714";
    border: 0.1em solid #000;
    border-radius: 0.2em;
    display: inline-block;
    width: 25px;
    height: 25px;
    padding-left: 0.2em;
    padding-bottom: 0.3em;
    margin-right: 10px;
    vertical-align: bottom;
    color: transparent;
    transition: .2s;
  }
  
  input[type=checkbox] + label:active:before {
    transform: scale(0);
  }
  
  input[type=checkbox]:checked + label:before {
    background-color: #D90429;
    border-color: #D90429;
    color: #fff;
  }
  
  input[type=checkbox]:disabled + label:before {
    transform: scale(1);
    border-color: #aaa;
  }
  
  input[type=checkbox]:checked:disabled + label:before {
    transform: scale(1);
    background-color: #bfb;
    border-color: #bfb;
  }

  /* checkboc end */

.txt-fld {
    font-size: 14px;
}

.select__skill_category {
  width: 100%;
}

.basic-multi-select.css-2b097c-container {
  width: 100%;
}

.select__control.css-yk16xz-control {
  border-radius: 20px;
  padding: 3px;
}