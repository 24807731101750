.text-component.list-tkt {
    border-bottom: 2px solid #eee; 
    background: #fff;
}
.ticket__desc_status .ticket_status {
    font-size: 14px;margin-bottom: 0px;color: #2b2d42;
}
.ticket__desc_decs .desc_attch {
    font-size: 22px;color: #111;margin-top: 4px;
}

.ticket__desc_decs .desc_attch_ic {
    float: right
}
.ticket__desc_decs .desc_attch_ic > i {
    font-size: 22px;color: #111;margin-top: 4px;margin-right: 10px;
}
.ticket__desc_decs .desc_histr {
    font-size: 22px;color: #111;margin-top: 4px; margin-right: 20px;
}
.ticket__desc_decs .ttl__des {
    font-size: 15px;line-height: 24px;
}


#mybutton {
    position: fixed;
    bottom: 85px;
    width: 100%;
}
.feedback {
    background-color: #D90429;
    color: white;
    padding: 10px 20px;
    border-radius: 0px;
    border-color: #D90429;
    width: 100%;
    border: none;
    width: 50%;
    float: left;
}

.feedback.accept {
    background: #2b2d42 !important;
}