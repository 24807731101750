/* .main-profilee {
    padding-top: 15px
} */

.profile-block {
    position: relative;
    overflow: hidden;
    max-width: 100%;
}

.profile-block .user-heading {
    background: #D90429 none repeat scroll 0 0;
    border-radius: 4px 4px 0 0;
    color: #fff;
    padding: 20px;
    text-align: center;
}

.profile-block .user-heading a img {
    border-radius: 50%;
    margin: auto auto 10px;
    display: block;
    height: 140px;
    width: 140px;
    border: 10px solid rgba(255, 255, 255, 0.3);
}

.profile-block .user-heading h1 {
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.profile-block .user-heading p {
    color: #fff;
    font-size: 12px;
    margin-bottom: 10px;
    text-align: center;
}

.profile-block ul {
    padding: 0;
    margin: 0;
    display: block
}

.profile-block ul > li {
    line-height: 30px;
    margin-top: 0;
}

.profile-block .nav > li > a {
/*    background: #e7e7e7;*/
    border-radius: 0;
    color: #000;
    display: block;
    font-size: 14px;
    padding: 10px 20px;
    position: relative;
    text-align: left;
    text-decoration: none;
    font-weight: 500
}

.profile-block ul > li > a > i {
    color: #000;
    font-size: 16px;
    padding-right: 10px;
}

.profile-block ul > li > a:hover,
.profile-block ul > li > a:focus,
.profile-block ul li.active a {
    background: #fff !important;
    border-radius: 0;
    color: #000 !important;
    font-weight: 500 !important;
}

.nextt {
    float: right;
    margin-top: 5px;
}

.right__menu_cr.text-center img {
    height: 150px;
}