.custom-top-search{
    width: 100%;
  /*  text-align: center;*/
  /*  position: absolute;*/
    color: #ffffff;
  }
  .custom-chat-title{
      padding: 25px 0 10px 0;
  }
  .custom-h4{
      color: #2b2d42;
      font-weight: bold;
      font-size: 18px;
  }
  .custom-chat-p{
      font-size: 13px;
      margin-top: 2%;
      color: #a4a4a4;
  }
  .custom-chat-grid{
      padding: 10px 20px;
  }
  .chat-p-img{
      width: 100%;
      height: auto;
  }
  .custom-chat-name{
      margin-top: 5%;
      font-size: 14px;
      font-weight: bold;
      color: #2b2d42;
  }
  .custom-last-chat{
      margin-top: 1%;
      font-size: 13px;
      color: #a4a4a4;
  }
  .no-pl{
      padding-left: 0px;
  }
  .chat-profile-spacing{
      padding: 5px 0;
  }
  .chat-active{
      background: #f3f4f9;
      border-left: 5px solid #D90429;
  }
  .custom-scroll{
  /*	height: 85vh;*/
      overflow-y: scroll;
  }
  header.cd-main-header.js-cd-main-header {
      position: fixed;
      width: 100%;
  }