.main-assin_num {
    /* text-align: center; */
    /* position: absolute; */
    position: relative;
    /* left: 28%; */
}

.fix__crc {
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
}

.main__top_dsh {
    background: #fff;
    text-align: center;
}

.main__top_dsh_main {
    padding: 15% 0;
}

.main__top_dsh_main .asign__count {
    text-align: center;
}


ul.main-assin_num {
    list-style: none;
}

ul.main-assin_num li {
    display: inline-block;
    background-color: #ffffff;
    margin: 15px 0 0 0;
    border-radius: 50%;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

ul.main-assin_num li a {
    color: #2B2D42;
    display: table-cell;
    /*	 vertical-align: middle;*/
    text-align: center;
    text-decoration: none;
    height: 150px;
    width: 150px;
    font-size: 38px;
    padding-top: 22px;
}

ul.main-assin_num li a:hover,
ul.main-assin_num li a:active {
    color: #2B2D42 !important;
    text-decoration: none;
}

.ticket__section {
    border: 1px solid rgba(170, 170, 170, 0.5);
    border-top: 0;
    border-right: none;
    border-left: none;
}

.fixes__head {
    padding: 8px 10px;
    font-size: 16px;
    font-weight: bold;
    color: #D90429;
}

.fixes__head a {
    padding: 8px 10px;
    font-size: 16px;
    font-weight: bold;
    color: #D90429;
}

.hide__container {
    display: none;
}

.pad-4{
    padding-bottom: 4%;
}
.pad-2{
    padding-bottom: 2%;
}
.pad-1{
    padding-bottom: 1%;
}
.color-green
{
    color: #2b2d42;
}
.color-dark{
    color: #2b2d42;
}
.btn-success{
    color: #fff;
    background-color: #2b2d42;
    border-color: #2b2d42;
    border-radius: 0px;
}
.btn-danger{
    color: #fff;
    background-color: #8D99AE;
    border-color: #8D99AE;
    border-radius: 0px;
}
.btn-custom{
    color: #fff;
    background-color: #D90429;
    border-color: #D90429;
    border-radius: 0px;
}
.custom-amount-btn {
    font-size: 14px;
    padding: 7px 25px;
    border-radius: 20px;
}
.m-b-2{
    margin-bottom: 2%;
}

.empty-ticket-img{
  height: 200px;
  width: auto;
  padding-bottom: 1%;
}
.pad-t-5 {
    padding-top: 5%;
}

.you-are-in-chat {
    /* box-shadow: 5px 10px 18px #888888;
    position: absolute;
    right: 0;
    padding: 10px; */

    /* box-shadow: 5px 5px 10px #888888f7;
    position: absolute;
    right: 0;
    padding: 5px;
    z-index: 1;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 12px;
    font-weight: 500; */

    padding: 2px 5px;
    font-size: 12px;
    font-weight: 600;
    background: #2B2D42;
}
 .chatContainer .you-are-in-chat {
    color: #8D99AE;
}
.chatContainer {
    text-decoration: none;
}

.cs_ficn_cls {
    font-size: 12px;
    margin-right: 5px;
    color: #2B2D42;
    width: 16px;
}

i.fa.fa-user.cs_ficn_cls {
    margin-right: 11px;
}

.chat-online-icn {
    color: #D90429;
    /* margin-top: 7px; */
    padding: 10px;
    /* margin-right: 5px */
}

.chat-online-blink {
    animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
  }
  @keyframes blinker {  
    from { opacity: 1; }
    to { opacity: 0; }
  }