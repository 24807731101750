.toolbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 56px;
    background: #D90429;
}

.toolbar__navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem
}

.toolbar__logo a {
     color: white;
     text-decoration: none;
     font-size: 1.5rem;
}

.spacer {
    flex: 1;
}

.toolbar__logo {
    /* margin-left: 1rem; */
    margin-left: 30%;
}

.toolbar_navigation-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar_navigation-items li {
    padding: 0 1.5rem;
}

.toolbar_navigation-items a {
    color: white;
    text-decoration: none;
}

.toolbar_navigation-items a:hover, .toolbar_navigation-items a:active {
    color: orange;
    text-decoration: none;
}

@media (max-width: 768px) {
    .toolbar_navigation-items {
        display: none;
    }
}

/* @media (min-width: 769px) {
    .toggle___button {
        display: none;
    }
} */


.searchIcons {
    padding: 0
}
.bellIcons {
    padding: 0; margin-left: 20px;
}
.ellipseIcons {
    float: right;margin-right: 10px; margin-left: 20px;
}
.top_toolbr {
    display: inline-flex;
}

.ticket_cancel {
    font-size: 22px;
    color: #fff;
    margin-left: 8px;
    margin-top: 4px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 45px;
    right: 0;
}
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
.show {
    display: block;
}

.badge {
    position: absolute;
    top: 6px;
    right: 3%;
    border-radius: 50%;
    background-color: #2B2D42;
    color: white;
    width: 20px;
    height: 20px;
    padding-top: 5px;
  }

  @media (max-width: 1220px) {
    .badge {
        right: 4%
    }
  }
  @media (max-width: 1010px) {
    .badge {
        right: 5%
    }
  }
  @media (max-width: 756px) {
    .badge {
        right: 6%
    }
  }
  @media (max-width: 670px) {
    .badge {
        right: 7%
    }
  }
  @media (max-width: 576px) {
    .badge {
        right: 8%
    }
  }
  @media (max-width: 480px) {
    .badge {
        right: 9%
    }
  }
  @media (max-width: 420px) {
    .badge {
        right: 10%
    }
  }
  @media (max-width: 370px) {
    .badge {
        right: 13%
    }
  }