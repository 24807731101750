.user_pending_amnt {
    margin: 10px;
    background: white;
    padding: 10% 0;
}
.user__pmnt_sng_cr {
    padding: 10px;
    margin: 10px;
    background: #ffffff;
    border-radius: 5px;
}
.color_ts {
    color: #D90429;
}
.fnt_bld {
    font-weight: bold
}
.text_pend_ttl {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 16px;
    font-weight: 600;
}
.text_pend_cnt {
    padding-top: 5px;
    font-size: 20px;
    color: #D90429;
    font-weight: bold;
    letter-spacing: 2px;
}