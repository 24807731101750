.font-18 {
    font-size: 18px;
}

.font-bold {
    font-weight: bold;
}

.mt-3 {
    margin-top: 3%;
}

.custom-legal-p-tag {
    margin: 2% 0;
    text-align: justify;
}

.custom-legal-sub-title {
    font-weight: bold;
    font-size: 14px;
    margin-top: 2%;
}

.custom-legal-sub-ul-title {
    font-size: 14px;
    margin-top: 2%;
}

.pad-l-5 {
    padding-left: 5%;
}

.pad-l-10 {
    padding-left: 10%;
}

.pad-l-12 {
    padding-left: 12%;
}