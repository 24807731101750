.fixes__head {
    padding: 8px 10px;
    font-size: 16px;
    font-weight: bold;
    color: #D90429;
}

.fixes__content_sng {
    padding: 10px;
    margin: 0px 10px;
    background: white;
    border-radius: 5px;
    margin-bottom: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.spacee {
    flex: 1;
}

.fixes__content_sng_hd {
    display: flex;
}

.fixes__content_sng_hd .fixes__content_sng_hd_ttl {
    font-size: 16px;
    font-weight: bold;
}

.fixes__content_sng .trk__id_nmbr {
    font-size: 13px;
    margin-top: 6px;
}

.fixes__content_ftr {
    display: flex;
}

p.fixes__content_sng_hd_dte {
    font-size: 14px;
}

.fixes__content_ftr a {
    font-size: 12px;
    border-radius: 20px;
    border: 1px solid;
    padding: 3px 10px;
    margin-top: 12px;
    color: #8D99AE;
    text-decoration: none;
}

.fixes__content_ftr .sts {
    margin-top: 12px;
    font-size: 13px;
    font-weight: 600;
}

.ldetails.list_cases a {
    color: #8D99AE;
}

.text-success {
    color: #D90429;
}