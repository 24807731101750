.fixesAcceptConfirmation {
    display: flex;
    /* margin-top: 5px */
}

 .fixesAcceptConfirmation p {
    width: 50%;
    text-align: center;
    line-height: 1.4;
}

.fixesAcceptConfirmation .reject {
    background: #D90429;
    color: #ffffff;
    border-radius: 20px;
    padding: 5px;
    margin: 0 5px;
}

.fixesAcceptConfirmation .accept {
    background: #2B2D42;
    color: #ffffff;
    border-radius: 20px;
    padding: 5px;
    margin: 0 5px;
}

.fixesAcceptConfirmation .snooze {
    background: #8D99AE;
    color: #ffffff;
    border-radius: 20px;
    padding: 5px;
    margin: 0 5px;
}
.fixesAcceptConfirmation .accept .material-icons,
.fixesAcceptConfirmation .reject .material-icons, 
.fixesAcceptConfirmation .snooze .material-icons {
    color: #ffffff;
    font-size: 14px;
    margin-right: 5px;
}

.swal2-popup {
    width: 300px;
}
.swal2-title {
    font-size: 16px;
}
.swal2-content {
    font-size: 14px;
}
.swal2-styled {
    padding: 5px 12px;
}
.swal2-styled:focus {
    box-shadow: none;
}
.connectmsg {
    padding: 5px 10px;
}
.snze-msg {
    padding: 5px 10px;
}