.side-drawer {
  height: 100%;
  background: white;
  /* box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5); */
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
  overflow: auto;
}

@media (min-width: 769px) {
  /* .side-drawer {
    display: none;
  } */
}

/* CSS Starts Here */


li {
  float: none;
  line-height: 79px;
}

.user-view {
  position: relative;
  padding: 32px 32px 0;
  margin-bottom: 8px;
}

.user-view .circle {
  height: 64px;
  width: 64px;
}

.user-view .circle,
.user-view .name,
.user-view .email {
  display: block;
}

.circle {
  border-radius: 50%;
}

.user-view .name {
  margin-top: 1px;
  font-weight: 500;
}

.user-view .name,
.user-view .email {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.6px;
  font-family: 'poppins', sans-serif;
}

.user-view .background {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.user-view .background img {
  height: 100%;
}

.user-view>a {
  height: auto;
  padding: 0;
  color: #fff;
  text-decoration: none;
}

li>a {
  color: #8D99AE;
  display: block;
  font-size: 14px;
  font-weight: 600;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
  text-decoration: none;
}

li>a>i,
li>a>[class^="mdi-"],
li>a li>a>[class*="mdi-"],
li>a>i.material-icons {
  float: left;
  height: 48px;
  line-height: 48px;
  margin: 0 32px 0 0;
  width: 24px;
  color: #8D99AE;
}

li.nv__menu {
  border-bottom: 1px solid rgba(170, 170, 170, 0.1);
}

.left-nav__link:focus {
  outline: none;
  text-decoration: none;
  background: none;
}

.nv_submenu {
  border-bottom: none !important;
}

.pull-right-count {
  float: right;
}

.active__menu_cl {
  color: #D90429 !important;
}

.active__menu {
  background: rgba(216, 43, 42, 0.2);
  color: #D90429;
}

li.nv__menu a:hover {
  color: #8D99AE;
  text-decoration: none;
}
.white-text {
  color: #ffffff;
}